$(function () {
  $('a[rel*=leanModal]').leanModal({
    top: 50,                     // モーダルウィンドウの縦位置を指定
    overlay: 0.5,               // 背面の透明度
    closeButton: ".modal_close"  // 閉じるボタンのCSS classを指定
  });


  $('.js-tab-nav li').click(function () {
    //.index()を使いクリックされたタブが何番目かを調べ、
    //indexという変数に代入します。
    var index = $('.js-tab-nav>li').index(this);
    if ($(this).children().hasClass('disabled')) {
    } else {
      //コンテンツを一度すべて非表示にし、
      $('.js-tab-cont>section').css('display', 'none');

      //クリックされたタブと同じ順番のコンテンツを表示します。
      $('.js-tab-cont>section').eq(index).css('display', 'block');

      //一度タブについているクラスselectを消し、
      $('.js-tab-nav>li>a').removeClass('active');

      //クリックされたタブのみにクラスactiveをつけます。
      $(this).children("a").addClass('active')
    }
    ;
  });

  // alert-boxの非表示
  $(".js-alert-box__close").click(function () {
    $(this).parents(".js-alert-box").css("display", "none");
  });

  // info-boxの非表示
  $(".js-info-box__close").on("click", function (ev) {
    ev.preventDefault();
    $(this).closest(".info-box").css("display", "none");
  });

  // 検索条件の追加
  var url = location.href;
  if (url.match("search_form")) {
    $(".js-search--detail").show();
    $(".js-btn--add-conditions").hide();

  } else {
    $(".js-btn--add-conditions").click(function () {
      $(".js-search--detail").toggle("slow");
      $(".js-btn--add-conditions").toggle();
    });
  }

  // スムーススクロール
  $('a[href^="#"]').click(function (event) {
    // アンカーの値取得
    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href === "#" ? 'html' : href);
    if ($(this).is('.js-no-scroll') || target.is(':not(:visible)') || target.is('.js-no-scroll')) {
      // スクロールせずに終了
      return false;
    }
    // 移動先を数値で取得
    var position = target.offset().top;
    // スムーススクロール
    new SmoothScroll(position).swing();
    event.preventDefault();
  });

  //矢印付きアコーディオン
  $('.js_acMenu > dt , .js-acBtn--pt01 , .js-acBtn--pt02 , .js-acBtn--pt03').on('click', function() {
    $(this).toggleClass('active');
    $(this).next().toggle();
  });
});
(function($){
  $(window).keydown(function(e){
    // control + option + O (ctrl + alt + O) で内部情報を表示
    if (e.ctrlKey && e.altKey && e.keyCode === 79) {
      if ($('#_job_detail_window').size() > 0) {
        $('#_job_detail_window').remove();
        return;
      }
      if ($('#_search_detail_window').size() > 0) {
        $('#_search_detail_window').remove();
        return;
      }

      var data = $('[data-job-source]').eq(0).data('jobSource');
      var search_data = $('[data-search-source]').data('searchSource');
      if (data) {
        var output = {
          '検索用テキスト': data.text,
          'ID': data.id,
          '公開日時': data.start_publishing_at,
          '更新日順': data.updated_at,
          '特例ポイント': data.special_point,
          'おすすめ順': data.recommend_point
        };
        var $table = $('<table id="_job_detail_window"></table>');
        $.each(output, function(key, val){
          $table.append(
            $('<tr></tr>').append(
              $('<th></th>').text(key)
            ).append(
              $('<td></td>').text(val)
            )
          );
        });

        $('body').eq(0).append($table);
      } else if (search_data) {
        var output = [];
        $.each(search_data, function() {
          output.push({
            'ID': this.id,
            '公開日時': this.start_publishing_at,
            '更新日順': this.updated_at,
            '特例ポイント': this.special_point,
            'おすすめ順': this.recommend_point
          });
        });
        var $table = $('<table id="_search_detail_window"></table>');
        $.each(output, function(key, val){
          $table.append(
            $('<tr></tr>').append(
              $('<th></th>').text('表示場所')
            ).append(
              $('<td></td>').text(key + 1 + '件目')
            )
          );
          $.each(val, function(k, v){
            $table.append(
              $('<tr></tr>').append(
                $('<th></th>').text(k)
              ).append(
                $('<td></td>').text(v)
              )
            );
          });
        });

        $('body').eq(0).append($table);
      }
    }
  });
})(jQuery);
_.templateSettings = {
  interpolate : /\{\%=(.+?)\%\}/g,
  escape : /\{\%-(.+?)\%\}/g,
  evaluate : /\{\%(.+?)\%\}/g
}
;


